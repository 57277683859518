import { Button, Card, message, Popover, Switch, Typography } from 'antd';
import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { fetchCurrentUser } from 'src/slices/auth';
import { LoggedIntercom } from 'src/utils/loggedIntercom';
import { theme } from '../constants/theme';
import { apiRequest } from '../helpers/api';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { getFinancingNeeds } from '../slices/financingNeed';
import { getNgo } from '../slices/ngo';
import { OfferModal } from 'src/components/OfferModal';
import { checkHasPaidOffer, isOrganizationPremium } from '../utils/projects';

const { Text, Title } = Typography;

function useHover<T>(): [MutableRefObject<T>, boolean] {
  const [value, setValue] = useState<boolean>(false);
  const ref: any = useRef<T | null>(null);
  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);
  useEffect(() => {
    const node: any = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [ref.current]);
  return [ref, value];
}

const useStyles = createUseStyles({
  container: {
    backgroundImage: `url("../../images/dashboardLogo.png")`,
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    padding: '3.98% 4.59%',
    flexDirection: 'column',
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  cardTitle: {
    fontFamily: "'Baloo 2'",
    fontWeight: 'bold',
    fontSize: '1vw !important',
    lineHeight: '1 !important',
    margin: '0 0 4.76% 0 !important',
    minWidth: '12vw',
    maxWidth: '12vw',
  },
  title: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '2.3vw !important',
    lineHeight: '1 !important',
    margin: '0 0 4.76% 0 !important',
  },
  dashboardTitle: {
    fontSize: '0.98vw',
    fontWeight: 'bold',
  },
  projectContainer: {
    display: 'grid',
    columnGap: '3.372%',
    gridTemplateColumns: 'repeat(auto-fill, minmax(17.45vw, 0fr))',
  },
  card: {
    backgroundColor: '#fcfcfc',
    minWidth: '17.45vw',
    minHeight: '18vw',
    marginTop: '14.71%',
    width: '17.45vw',
    borderRadius: '1vw',
    webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    '& .ant-card-body': {
      padding: '0 5%',
      fontSize: '0.8125vw',
      ['@media screen and (max-width: 1000px)']: {
        fontSize: '8.5px',
      },
    },
  },
  headerContainer: {
    marginBottom: '4vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  subtitle: {
    fontSize: '0.98vw',
  },
  bannerImage: {
    width: '16.875vw',
    height: '7.49vw',
    borderTopRightRadius: '0.3125vw !important',
    borderTopLeftRadius: '0.3125vw !important',
  },
  button: {
    height: '4vw',
    width: '19.98%',
    backgroundColor: theme.palette.primary,
    borderColor: 'white',
    webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    color: 'white',
    fontWeight: 'bold',
    position: 'relative',
    borderRadius: '1vw',
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary,
      border: `1px solid ${theme.palette.primary}`,
    },
  },
  cardButton: {
    backgroundColor: '#fcfcfc',
    border: `1px solid ${theme.palette.primary}`,
    borderRadius: '0.4vw',
    color: theme.palette.primary,
    '&:hover': {
      backgroundColor: theme.palette.primary,
      border: `1px solid ${theme.palette.primary}`,
      color: 'white',
    },
  },
  cardButtonUnsubscribe: {
    marginTop: '0.2vw',
    backgroundColor: '#fcfcfc',
    border: `1px solid ${theme.palette.primary}`,
    borderRadius: '0 0 0.4vw 0.4vw',
    color: theme.palette.primary,
    '&:hover': {
      backgroundColor: theme.palette.primary,
      border: `1px solid ${theme.palette.primary}`,
      color: 'white',
    },
  },
  visibilityButton: {
    fontSize: '0.6vw',
    textAlign: 'center',
    fontWeight: 'normal',
    marginTop: '0.4vw',
    backgroundColor: theme.palette.primary,
    border: `1px solid ${theme.palette.primary}`,
    borderRadius: '0 0 0.4vw 0.4vw',
    color: 'white',
    cursor: 'default',
  },
  nationaleButton: {
    marginTop: '0.4vw',
    backgroundColor: '#2dc4a8',
    border: '1px solid #2dc4a8',
    color: 'white',
    '&:hover': {
      backgroundColor: 'white',
      border: '1px solid #2dc4a8',
      color: '#2dc4a8',
    },
  },
  textButton: {
    color: theme.palette.primary,
    '&:hover': {
      color: 'white',
    },
  },
  cardMeta: {
    '& .ant-card-head-title': {
      whiteSpace: 'break-spaces',
      width: '100%',
    },
    '& .ant-card-head-wrapper': {
      flexDirection: 'column',
      overflow: 'hidden',
    },
    '& .ant-card-extra': {
      marginLeft: '0',
    },
    '& .ant-card-meta-description': {
      color: 'black !important',
      lineHeight: 1,
    },
    '& .ant-card-meta-title': {
      fontFamily: "'Baloo 2'",
      fontWeight: '600',
      fontSize: '1vw',
      lineHeight: 1,
      marginBottom: '0.9375vw !important',
    },
  },
  buttonDonor: {
    color: theme.palette.primary,
    fontWeight: 'bold',
    paddingBottom: '2%',
    '& .ant-btn': {
      width: '100%',
      fontSize: '0.6875vw',
      ['@media screen and (max-width: 1000px)']: {
        fontSize: '7.5px',
      },
      whiteSpace: 'normal',
      wordBreak: 'break-all',
      height: '100%',
      padding: '2.14% 8.02%',
    },
  },
  openProjectButton: {
    marginTop: '0.4vw',
    color: theme.palette.primary,
    fontWeight: 'bold',
    paddingBottom: '2%',
    '& .ant-btn': {
      width: '100%',
      fontSize: '0.6875vw',
      ['@media screen and (max-width: 1000px)']: {
        fontSize: '7.5px',
      },
      whiteSpace: 'normal',
      wordBreak: 'break-all',
      height: '100%',
      padding: '2.14% 8.02%',
    },
  },
  expirationDate: {
    color: theme.palette.primary,
    fontSize: '0.6125vw !important',
  },
  expirationDateAlt: {
    color: '#000',
    fontSize: '0.6125vw !important',
  },
  offer: {
    fontSize: '0.6125vw !important',
  },
  offerVisibility: {
    fontSize: '0.6125vw !important',
    color: 'black',
  },
  offerComplete: {
    fontSize: '0.6125vw !important',
    color: 'green',
    width: '100%',
  },
  expirationDateText: {
    fontSize: '0.6125vw !important',
  },
  switchDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '10px',
    gap: '10px',
  },
  buttonNational: {
    minHeight: '4vw',
    background:
      'linear-gradient(to right, rgba(0, 0, 255, 0.2) 0%, rgba(0, 0, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 66.66%, rgba(255, 0, 0, 0.2) 66.66%)',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    color: '#000',
    '&:hover': {
      background:
        'linear-gradient(to right, rgba(0, 0, 255, 0.3) 0%, rgba(0, 0, 255, 0.3) 33.33%, rgba(255, 255, 255, 0.3)' +
        ' 33.33%, rgba(255, 255, 255, 0.3) 66.66%, rgba(255, 0, 0, 0.3) 66.66%)',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
    },
  },
  buttonNationalSettings: {
    background:
      'linear-gradient(to right, rgba(0, 0, 255, 0.2) 0%, rgba(0, 0, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 66.66%, rgba(255, 0, 0, 0.2) 66.66%)',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    color: '#000',
    '&:hover': {
      background:
        'linear-gradient(to right, rgba(0, 0, 255, 0.3) 0%, rgba(0, 0, 255, 0.3) 33.33%, rgba(255, 255, 255, 0.3)' +
        ' 33.33%, rgba(255, 255, 255, 0.3) 66.66%, rgba(255, 0, 0, 0.3) 66.66%)',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
    },
    borderTop: '0px',
  },
  nationalTitle: {
    fontSize: '0.92vw',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bannerContainer: {
    marginTop: '2.5vw',
    backgroundColor: theme.palette.primary,
    padding: '2.5vw 2.5vw 2.5vw 2.5vw',
    borderRadius: '1vw',
  },
  bannerContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  bannerInfos: {
    display: 'flex',
    flexDirection: 'column',
  },
  bannerTitle: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '2vw !important',
    lineHeight: '1 !important',
    margin: '0 0 4.76% 0 !important',
    color: 'white',
  },
  bannerSubtitle: {
    fontSize: '1vw',
    color: 'white',
  },
  bannerButton: {
    height: '4vw',
    width: '19.98%',
    backgroundColor: 'white',
    borderColor: theme.palette.primary,
    webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    color: theme.palette.primary,
    fontWeight: 'bold',
    position: 'relative',
    borderRadius: '1vw',
    '&:hover': {
      backgroundColor: theme.palette.primary,
      color: 'white',
      border: '1px solid white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
});

interface Props {
  setIsNavBarHidden?: (isHidden: boolean) => void;
}

export const Projects: React.VFC<Props> = ({ setIsNavBarHidden }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [hoverRef] = useHover<HTMLDivElement>();
  const { user, users } = useAppSelector(({ auth: { user, users } }) => ({
    user,
    users,
  }));
  const [showOfferModal, setShowOfferModal] = useState(false);

  const { financingNeeds } = useAppSelector(
    ({ financingNeed: { financingNeeds } }) => ({
      financingNeeds,
    }),
  );

  useEffect(() => {
    if (setIsNavBarHidden) {
      setIsNavBarHidden(false);
    }
  }, []);

  useEffect(() => {
    if (user?.ngoId) {
      dispatch(getNgo(user.ngoId));
    }
  }, [user?.ngoId]);

  useEffect(() => {
    dispatch(getFinancingNeeds(user?.ngoId));
  }, []);

  useEffect(() => {
    fetchCurrentUser();
  }, [users]);

  const onClickNewProject = () => {
    if (financingNeeds.allIds.length + 1 > 10) {
      message.error('Vous ne pouvez pas créer plus de 10 projets.');
    } else {
      history.push('/newProject');
    }
  };

  const isPremium = useMemo(() => {
    return isOrganizationPremium(Object.values(financingNeeds.byId));
  }, [financingNeeds]);

  function renderFinancingNeeds() {
    return (
      <div className={classes.projectContainer}>
        {Object.values(financingNeeds.byId).map((financingNeed) => (
          <Card
            bordered={false}
            hoverable
            key={financingNeed._id}
            className={classes.card}
            onClick={() => {
              history.push(`/projects/${financingNeed._id}`);
            }}
          >
            <div style={{ height: '100%', padding: '2vw 0 2vw 0' }}>
              <div className={classes.cardHeader}>
                <div>
                  <Title className={classes.cardTitle}>
                    {financingNeed.name}
                  </Title>
                </div>
                <br />
                <div className={classes.switchDiv}>
                  <Popover
                    content={
                      <div>
                        <p>
                          Désactivé : les mécènes ne pourront pas voir le projet
                        </p>
                        <p>Activé : les mécènes pourront voir le projet</p>
                      </div>
                    }
                    trigger="hover"
                  >
                    <Switch
                      checked={financingNeed.published}
                      onClick={async (status, e) => {
                        e.stopPropagation();
                        await apiRequest(
                          'PATCH',
                          `/financing-need/visibility/${financingNeed._id}`,
                          undefined,
                          {},
                        );
                        dispatch(getFinancingNeeds(user?.ngoId));
                      }}
                    />
                  </Popover>
                </div>
              </div>
              <br />
              <div className={classes.openProjectButton}>
                <Button
                  style={{ padding: '0.8vw 0 0.8vw 0' }}
                  className={classes.cardButton}
                  onClick={() => {
                    history.push(`/projects/${financingNeed._id}`);
                  }}
                >
                  Ouvrir le projet
                </Button>
              </div>
              <div className={classes.openProjectButton}>
                <Button
                  style={{ padding: '0.8vw 0 0.8vw 0' }}
                  className={classes.cardButton}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (isPremium) {
                      history.push(`/projects/${financingNeed._id}/donors`);
                      return;
                    }
                    history.push(
                      `/subscription/${financingNeed._id}/${isPremium}`,
                    );
                  }}
                >
                  Tableau de suivi
                </Button>
              </div>
              <div className={classes.buttonDonor}>
                <Button
                  style={{
                    padding: '0.8vw 0 0.8vw 0',
                    wordBreak: 'normal',
                    borderRadius: '0.4vw',
                  }}
                  className={classes.nationaleButton}
                  onClick={(event) => {
                    LoggedIntercom(
                      user?.firstName + ' ' + user?.lastName,
                      user?.email,
                      user?._id,
                      'ngoClickedSearchDonorsFrance',
                    );
                    event.stopPropagation();
                    if (isPremium) {
                      history.push(`/projects/${financingNeed._id}/database`);
                      return;
                    }
                    history.push(
                      `/freemium-projects/${financingNeed._id}/database/${isPremium}`,
                      // history.push(
                      //   `/subscription/${
                      //     financingNeed._id
                      //   }/complete/${checkOfferComplete(
                      //     financingNeed,
                      //   )}/${checkOfferBFC(financingNeed)}`,
                    );
                    return;
                  }}
                >
                  Mécènes correspondants
                </Button>
              </div>
            </div>
          </Card>
        ))}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <div>
            <Text className={classes.title}>Bonjour {user?.firstName}</Text>
          </div>
          <div>
            <Text className={classes.subtitle}>
              Bienvenue sur votre tableau de bord
            </Text>
          </div>
        </div>
        <Button
          type="primary"
          onClick={onClickNewProject}
          className={classes.bannerButton}
          ref={hoverRef}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <div
              style={{
                fontSize: '0.9375vw',
                lineHeight: 1,
              }}
            >
              Ajouter un nouveau projet
            </div>
          </div>
        </Button>
      </div>
      <div>
        <div className={classes.dashboardTitle}>
          <Text>Tous mes projets</Text>
        </div>
        <div>
          {Object.values(financingNeeds.byId) &&
          Object.values(financingNeeds.byId).length === 0 ? (
            <div className={classes.bannerContainer}>
              <div className={classes.bannerContent}>
                <div className={classes.bannerInfos}>
                  <div style={{ marginBottom: '1vw' }}>
                    <Text className={classes.bannerTitle}>
                      Afin de profiter au maximum de l’application Sonar, créez
                      votre premier projet.
                    </Text>
                  </div>
                  <div>
                    <Text className={classes.bannerSubtitle}>
                      N&apos;attendez plus pour accélérer vos recherches de
                      mécénat et
                      <br />
                      trouver les partenaires financiers idéaux pour votre
                      association.
                    </Text>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'end',
                  }}
                >
                  <Button
                    type="primary"
                    onClick={onClickNewProject}
                    className={classes.bannerButton}
                    style={{ width: 'auto' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '1vw',
                          lineHeight: 1,
                        }}
                      >
                        Ajouter un nouveau projet
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <>{renderFinancingNeeds()}</>
          )}
        </div>
      </div>
      <OfferModal
        isVisible={showOfferModal}
        requestClose={() => setShowOfferModal(false)}
        type="financingNeed"
        financingNeeds={Object.values(financingNeeds.byId).map(
          (financingNeed) => financingNeed,
        )}
        checkHasPaidOfferFN={checkHasPaidOffer}
      />
    </div>
  );
};
