import { Button, Typography, Dropdown, Menu, Tooltip, message } from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { TagList } from '../../components/TagList';
import { theme } from '../../constants/theme';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { Donor, publishDonors, updateDonor } from '../../slices/donor';
import ReadMore from '../../components/ReadMore';
import { Classification } from 'src/slices/classification';
import { User } from 'src/slices/auth';
import {
  FileOutlined,
  PlusCircleOutlined,
  LinkOutlined,
  LinkedinFilled,
  PlusCircleFilled,
  MinusCircleFilled,
  MoreOutlined,
  EditOutlined,
  BellFilled,
  BellOutlined,
} from '@ant-design/icons';
import { CallForTender } from 'src/slices/callForTender';
import { CallForTenderCards } from 'src/containers/CallForTenderCards';
import { FinancingNeed } from '../../slices/financingNeed';
import {
  addFollowedDonor,
  getNgo,
  removeFollowedDonor,
} from '../../slices/ngo';
import { isOrganizationPremium } from '../../utils/projects';

const { Title, Text, Link } = Typography;

const useStyles = createUseStyles({
  title: {
    width: '60%',
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.6875vw !important',
    lineHeight: '1 !important',
    margin: '0 0 0 0 !important',
  },
  moreOutlined: {
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    cursor: 'default',
  },
  firstLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleInformations: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    // alignSelf: 'center',
    lineHeight: '1 !important',
    marginBottom: '4.76% !important',
  },
  fiche: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1vw !important',
    lineHeight: '1 !important',
    marginBottom: '1% !important',
    color: '#5050F9 !important',
  },
  subtitle: {
    marginTop: '2%',
    marginBottom: '2.62%',
    color: theme.palette.secondary,
    fontWeight: 'bold',
    fontSize: 'calc(5px + 0.6vw)',
  },
  leftColumn: {
    width: '45vw',
    display: 'flex',
    flexDirection: 'column',
    padding: '6.13% 4.98%',
    backgroundColor: 'white',
    borderRadius: '0.3125vw',
    marginLeft: '-0.19%',
    minHeight: '100vh',
    height: '100%',
  },
  titleContainer: {
    position: 'relative',
    width: 'auto',
    height: 'auto',
    display: 'flex',
  },
  validateButton: {
    margin: '0 0.5vw 0.5vw 0',
    padding: '0.25vw 0.9375vw',
    fontSize: 'calc(5px + 0.4vw)',
    height: 'fit-content',
  },
  tag: {
    marginTop: '2.88%',
    '& .ant-tag': {
      margin: '0px 1.55% 2.20% 0px',
      wordBreak: 'break-word',
      whiteSpace: 'unset',
      borderColor: '#ececf8',
      backgroundColor: '#ececf8',
      lineHeight: 1.5,
      fontSize: 'calc(5px + 0.4vw)',
    },
  },
  description: {
    fontSize: '0.75vw',
  },
  logoContainer: {
    width: '17%',
    maxHeight: '100%',
    maxWidth: '15%',
    position: 'relative',
    top: 0,
    right: 0,
  },
  logoImage: {
    width: '17%',
    maxHeight: 'calc(100% + 1.11vh)',
    position: 'relative',
    top: '-1.11vh',
    right: 0,
  },
  logoImageRelative: {
    width: '17%',
    maxHeight: 'calc(100% + 1.11vh)',
  },
  displayLineBreak: {
    whiteSpace: 'pre-line',
    fontSize: '0.75vw',
    ['@media screen and (max-width: 1200px)']: {
      fontSize: '9px',
    },
    textJustify: 'auto',
    textAlign: 'justify',
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
  fileContainer: {
    display: 'flex',
  },
  filesDisplay: {
    margin: '0 0 0 0.3125vw',
  },
  validateCallForTender: {
    fontSize: 'calc(5px + 0.35vw)',
    marginLeft: '0.3vw',
    lineHeight: '1vw',
  },
  firstContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '15px 0 15px 0',
    alignItems: 'center',
    '& .anticon-heart': {
      fontSize: 'calc(8px + 0.5vw)',
      margin: '0em 0 0.5em 1.8%',
      color: theme.palette.primary,
    },
  },
  link: {
    marginRight: '0.90vw',
    width: 'fit-content',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  linkIcon: {
    color: theme.palette.primary,
    fontSize: '1.0625vw',
  },
  linkIconBellFilledRed: {
    fontSize: '18px',
    color: 'red',
  },
  linkIconBellFilledBlue: {
    fontSize: '18px',
    color: theme.palette.primary,
  },
  linkIconBellOutlined: {
    color: theme.palette.primary,
    fontSize: '18px',
    '&:hover': {
      color: theme.palette.secondary,
    },
  },
  alignedHeart: {
    marginTop: '4px',
  },
});

interface Props {
  displayedDonor: Donor;
  classification: Classification;
  donorId: string;
  user: User;
  callForTenders: CallForTender[];
  financingNeed?: FinancingNeed;
  financingNeeds?: FinancingNeed[];
  onAddDonor: (donorId: string, financingNeedId: string) => void;
  onRemoveDonor: (donorId: string, financingNeedId: string) => void;
}

const DonorPageLeftColumn: React.VFC<Props> = ({
  displayedDonor,
  classification,
  donorId,
  user,
  callForTenders,
  financingNeed,
  financingNeeds,
  onAddDonor,
  onRemoveDonor,
}) => {
  const dispatch = useAppDispatch();
  const { ngo } = useAppSelector(({ ngo: { ngo } }) => ({
    ngo,
  }));

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (user?.ngoId) {
      dispatch(getNgo(user.ngoId));
    }
  }, []);

  console.log('ngo', ngo);
  const classes = useStyles();
  const history = useHistory();

  const { tags } = classification;

  const currentDate = new Date();
  const [
    activeCallForTenders,
    missingCallForTenders,
    inactiveCallForTenders,
    futureCallForTenders,
  ] = callForTenders.reduce<
    [
      Array<CallForTender>,
      Array<CallForTender>,
      Array<CallForTender>,
      Array<CallForTender>,
    ]
  >(
    (result, tender) => {
      const openingDate = new Date(
        tender?.openingDate ? tender.openingDate : '',
      );
      const closingDate = new Date(
        tender?.closingDate ? tender.closingDate : '',
      );
      if (openingDate > currentDate) {
        result[3].push(tender);
      } else if (closingDate < currentDate) {
        result[2].push(tender);
      } else if (closingDate > currentDate) {
        result[0].push(tender);
      } else if (
        tender?.closingDate === null ||
        tender?.closingDate === undefined
      ) {
        result[1].push(tender);
      }
      return result;
    },
    [[], [], [], []],
  );

  const getActiveSorted = (callForTendersArray: CallForTender[]) => {
    callForTendersArray.sort((a, b) => {
      const aClosingDate = new Date(a?.closingDate ? a.closingDate : '');
      const bClosingDate = new Date(b?.closingDate ? b.closingDate : '');
      if (!a.closingDate || !b.closingDate) {
        return 0;
      }
      return aClosingDate.getTime() - bClosingDate.getTime();
    });
    return callForTendersArray;
  };

  const getInactiveSorted = (callForTendersArray: CallForTender[]) => {
    callForTendersArray.sort((a, b) => {
      const aClosingDate = new Date(a?.closingDate ? a.closingDate : '');
      const bClosingDate = new Date(b?.closingDate ? b.closingDate : '');
      if (!a.closingDate || !b.closingDate) {
        return 0;
      }
      return bClosingDate.getTime() - aClosingDate.getTime();
    });
    return callForTendersArray;
  };

  const getFutureSorted = (callForTendersArray: CallForTender[]) => {
    callForTendersArray.sort((a, b) => {
      const aOpeningDate = new Date(a?.openingDate ? a.openingDate : '');
      const bOpeningDate = new Date(b?.openingDate ? b.openingDate : '');
      if (!a.openingDate || !b.openingDate) {
        return 0;
      }
      return aOpeningDate.getTime() - bOpeningDate.getTime();
    });
    return callForTendersArray;
  };

  const sortedActiveCallForTenders =
    activeCallForTenders.length === 0
      ? activeCallForTenders
      : getActiveSorted(activeCallForTenders);

  const sortedInactiveCallForTenders =
    inactiveCallForTenders.length === 0
      ? inactiveCallForTenders
      : getInactiveSorted(inactiveCallForTenders);

  const sortedFutureCallForTenders =
    futureCallForTenders.length === 0
      ? futureCallForTenders
      : getFutureSorted(futureCallForTenders);

  const handleValidation = (_id: string) => {
    dispatch(publishDonors({ _id: _id }));
    history.push(`/donorsToValidate`);
  };

  const handleQualify = (_id: string) => {
    dispatch(
      updateDonor({
        updateDonorDto: { _id: _id, publicationStatus: 'toValidate' },
      }),
    );

    history.replace('/');
  };

  const handleReverseValidation = (_id: string) => {
    dispatch(
      updateDonor({
        updateDonorDto: { _id: _id, publicationStatus: 'assigned' },
      }),
    );
    history.push(`/donorsToQualify`);
  };

  const handleNotRelevant = (_id: string) => {
    dispatch(
      updateDonor({
        updateDonorDto: { _id, publicationStatus: 'notRelevant' },
      }),
    );
    history.goBack();
  };

  const renderFiles = () => {
    return displayedDonor.fileUrls?.map((file, key) => {
      const array = file.split('||||$$$$%%%%&&&&');
      return (
        <div key={key} className={classes.fileContainer}>
          <Button
            href={array[0]}
            target="_blank"
            className={classes.filesDisplay}
          >
            <FileOutlined />
            {array[1]}
          </Button>
        </div>
      );
    });
  };

  const renderLastCallForTenderValidation = () => {
    const date = displayedDonor?.lastCallForTenderValidation
      ? new Date(displayedDonor?.lastCallForTenderValidation)
      : undefined;
    return (
      displayedDonor?.lastCallForTenderValidation && (
        <div className={classes.validateCallForTender}>
          Dernière validation:
          <br />
          {new Intl.DateTimeFormat('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }).format(date)}
        </div>
      )
    );
  };

  const renderIconLink = (Icon: React.ComponentType<any>, url: string) => {
    return (
      <Link className={classes.link} href={url} target="_blank">
        <Icon className={classes.linkIcon} />
      </Link>
    );
  };

  const renderIconClick = (
    Icon: React.ComponentType<any>,
    onClick?: () => void,
    color?: string,
  ) =>
    color ? (
      <span className={classes.link} onClick={onClick}>
        <Icon className={classes.linkIcon} style={{ color: color }} />
      </span>
    ) : (
      <span className={classes.link} onClick={onClick}>
        <Icon className={classes.linkIcon} />
      </span>
    );

  const renderFinancingNeedsList = (
    financingNeedsOptions: FinancingNeed[],
    donorId: string,
    add: boolean,
  ) => (
    <>
      {financingNeedsOptions.map((financingNeedOption, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            add
              ? onAddDonor(donorId, financingNeedOption._id)
              : onRemoveDonor(donorId, financingNeedOption._id);
          }}
        >
          {financingNeedOption.name}
        </Menu.Item>
      ))}
    </>
  );

  const renderAddDonorSubMenu = (
    donor: Donor,
    financingNeedsOptions: FinancingNeed[] | undefined,
  ) => {
    return (
      !!financingNeedsOptions?.length && (
        <SubMenu title={'Ajouter à un projet'}>
          {renderFinancingNeedsList(financingNeedsOptions, donor._id, true)}
        </SubMenu>
      )
    );
  };

  const rendeRemoveDonorSubMenu = (
    donor: Donor,
    financingNeedsOptions: FinancingNeed[] | undefined,
  ) => {
    return (
      !!financingNeedsOptions?.length && (
        <SubMenu title={"Retirer d'un projet"}>
          {renderFinancingNeedsList(financingNeedsOptions, donor._id, false)}
        </SubMenu>
      )
    );
  };

  const renderAddRemoveProject = () => {
    if (financingNeed) {
      return financingNeed?.donors.includes(displayedDonor._id) ||
        !financingNeeds?.some(
          (financingNeed) => !financingNeed.donors.includes(displayedDonor._id),
        )
        ? renderIconClick(
            MinusCircleFilled,
            () => onRemoveDonor(displayedDonor._id, financingNeed._id),
            'red',
          )
        : renderIconClick(PlusCircleFilled, () =>
            onAddDonor(displayedDonor._id, financingNeed._id),
          );
    } else {
      const financingNeedsIncludeDonor = financingNeeds?.filter(
        (financingNeed) => financingNeed.donors.includes(displayedDonor._id),
      );
      const financingNeedsDontIncludeDonor = financingNeeds?.filter(
        (financingNeed) => !financingNeed.donors.includes(displayedDonor._id),
      );
      const menu = (
        <Menu>
          {!!financingNeedsDontIncludeDonor?.length &&
            !financingNeedsIncludeDonor?.length &&
            renderFinancingNeedsList(
              financingNeedsDontIncludeDonor,
              displayedDonor._id,
              true,
            )}
          {!!financingNeedsIncludeDonor?.length &&
            !financingNeedsDontIncludeDonor?.length &&
            renderFinancingNeedsList(
              financingNeedsIncludeDonor,
              displayedDonor._id,
              false,
            )}
          {!!financingNeedsDontIncludeDonor?.length &&
            !!financingNeedsIncludeDonor?.length && (
              <>
                {renderAddDonorSubMenu(
                  displayedDonor,
                  financingNeedsDontIncludeDonor,
                )}
                {rendeRemoveDonorSubMenu(
                  displayedDonor,
                  financingNeedsIncludeDonor,
                )}
              </>
            )}
        </Menu>
      );
      return (
        <Dropdown overlay={menu}>
          {!financingNeeds?.some(
            (financingNeed) =>
              !financingNeed.donors.includes(displayedDonor._id),
          )
            ? renderIconClick(MinusCircleFilled, undefined, 'red')
            : renderIconClick(PlusCircleFilled)}
        </Dropdown>
      );
    }
  };

  const menuMoreOutlined = (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push(`/donor/${donorId}/edit`);
        }}
        icon={<EditOutlined style={{ color: 'blue' }} />}
      >
        {'Modifier'}
      </Menu.Item>
    </Menu>
  );

  const onClickNewProject = () => {
    if (user?.role === 'admin') {
      history.push(`/donor/${displayedDonor?._id}/saveTenderDonor`);
    } else {
      if (
        displayedDonor?.callForTenders?.length &&
        user?.maxNumberOfProjects &&
        displayedDonor?.callForTenders?.length >= user.maxNumberOfProjects
      ) {
        message.error(
          user.maxNumberOfProjects === 1
            ? 'Vous ne pouvez pas créer plus de ' +
                user.maxNumberOfProjects +
                ' projet'
            : 'Vous ne pouvez pas créer plus de ' +
                user.maxNumberOfProjects +
                ' projets',
        );
      } else {
        if (user?.donorId) {
          history.push(`/donor/${user?.donorId}/saveTenderDonor`);
        }
      }
    }
  };

  return (
    <div className={classes.leftColumn}>
      <Title className={classes.fiche}>Fiche Mécène</Title>
      <div className={classes.titleContainer}>
        <div style={{ marginBottom: 'auto', marginTop: 'auto', width: '100%' }}>
          <div className={classes.firstLine}>
            <Title className={classes.title} style={{ maxWidth: '80%' }}>
              {displayedDonor.name}
            </Title>
            {displayedDonor.logoUrl && (
              <img
                src={displayedDonor.logoUrl}
                alt="logo"
                className={classes.logoImageRelative}
              />
            )}
            {(user?.role === 'admin' ||
              user?.role === 'contributor' ||
              user.donorId === donorId) && (
              <Dropdown
                overlay={menuMoreOutlined}
                placement={'bottomCenter'}
                trigger={['click']}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <MoreOutlined
                    style={{ color: '#5050f9' }}
                    className={classes.moreOutlined}
                  />
                </a>
              </Dropdown>
            )}
          </div>
          <div className={classes.firstContainer}>
            {(displayedDonor.homepageUrl || displayedDonor.otherUrl) &&
              renderIconLink(
                LinkOutlined,
                (displayedDonor.homepageUrl ||
                  displayedDonor.otherUrl) as string,
              )}
            {(displayedDonor.linkedinUrl ||
              displayedDonor.companyLinkedinUrl) &&
              renderIconLink(
                LinkedinFilled,
                (displayedDonor.linkedinUrl ||
                  displayedDonor.companyLinkedinUrl) as string,
              )}
            {user.ngoId != undefined &&
              isOrganizationPremium(financingNeeds ?? []) && (
                <Tooltip
                  placement="top"
                  title={
                    <span>Ajouter / Supprimer de votre tableau de suivi</span>
                  }
                >
                  {renderAddRemoveProject()}
                </Tooltip>
              )}
            {user.ngoId != undefined &&
              ngo &&
              isOrganizationPremium(financingNeeds ?? []) &&
              (!ngo.followedDonors ||
                !ngo?.followedDonors.includes(donorId)) && (
                // <Tooltip
                //   placement="top"
                //   title={<span>Ajouter à vos mécènes suivis</span>}
                // >
                //   <span
                //     className={classes.link}
                //     onClick={() =>
                //       dispatch(
                //         addFollowedDonor({ donorId: displayedDonor._id }),
                //       )
                //     }
                //   >
                //     <BellOutlined className={classes.linkIconBellOutlined} />
                //   </span>
                // </Tooltip>
                <Button
                  className={classes.link}
                  shape={'round'}
                  onClick={() =>
                    dispatch(addFollowedDonor({ donorId: displayedDonor._id }))
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '5px',
                    }}
                  >
                    <BellOutlined className={classes.linkIconBellOutlined} />
                    <span>S&apos;abonner</span>
                  </div>
                </Button>
              )}
            {user.ngoId != undefined &&
              ngo &&
              ngo.followedDonors &&
              ngo?.followedDonors.includes(donorId) && (
                <Button
                  className={classes.link}
                  shape={'round'}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={() =>
                    dispatch(
                      removeFollowedDonor({ donorId: displayedDonor._id }),
                    )
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '5px',
                    }}
                  >
                    {isHovered ? (
                      <BellFilled className={classes.linkIconBellFilledRed} />
                    ) : (
                      <BellFilled className={classes.linkIconBellFilledBlue} />
                    )}
                    {isHovered ? (
                      <span>Se désabonner</span>
                    ) : (
                      <span>Abonné</span>
                    )}
                  </div>
                </Button>
              )}
          </div>
          {(user?.role === 'admin' ||
            user?.role === 'contributor' ||
            user.donorId === donorId) && (
            <div style={{ display: 'flex', position: 'relative' }}>
              <Button
                className={classes.validateButton}
                onClick={onClickNewProject}
                type="dashed"
                icon={<PlusCircleOutlined />}
              >
                Ajouter un programme de soutien
              </Button>
              {user?.role === 'admin' && displayedDonor.usesCallsToTender && (
                <div>{renderLastCallForTenderValidation()}</div>
              )}
            </div>
          )}
          <div style={{ display: 'flex', position: 'relative' }}>
            {displayedDonor.publicationStatus === 'toValidate' &&
              user?.role === 'admin' && (
                <Button
                  className={classes.validateButton}
                  type="primary"
                  onClick={() => handleValidation(donorId)}
                >
                  Valider
                </Button>
              )}
            {displayedDonor.publicationStatus === 'assigned' &&
              user?.role === 'contributor' && (
                <>
                  <Button
                    className={classes.validateButton}
                    type="primary"
                    onClick={() => handleQualify(donorId)}
                  >
                    Envoyer à la validation
                  </Button>
                  <Button
                    className={classes.validateButton}
                    danger
                    type="primary"
                    onClick={() => handleNotRelevant(donorId)}
                  >
                    Non pertinent
                  </Button>
                </>
              )}
            {(displayedDonor?.publicationStatus === 'toValidate' ||
              displayedDonor?.publicationStatus === 'published') &&
              user?.role === 'admin' && (
                <Button
                  className={classes.validateButton}
                  type="primary"
                  onClick={() => handleReverseValidation(donorId)}
                >
                  Renvoyer à qualifier
                </Button>
              )}
          </div>
          {displayedDonor.tags && (
            <div className={classes.tag}>
              <TagList
                elementIds={displayedDonor.tags}
                collection={tags}
                color="default"
                collectionName="tags"
                highlightedIds={financingNeed?.tags ?? []}
                maxElements={10}
              />
            </div>
          )}
        </div>
      </div>
      {displayedDonor.description && (
        <>
          <Text className={classes.subtitle}>Description</Text>
          <ReadMore
            description={displayedDonor.description}
            maximumNumberOfCharacters={1396}
          />
        </>
      )}
      {user?.role === 'admin' && displayedDonor.comments && (
        <>
          <Text className={classes.subtitle}>Commentaires</Text>
          <Text className={classes.displayLineBreak}>
            {displayedDonor.comments}
          </Text>
        </>
      )}
      {user?.role === 'admin' && displayedDonor?.fileUrls?.length !== 0 && (
        <>
          <Text className={classes.subtitle}>Fichiers</Text>
        </>
      )}
      {user?.role === 'admin' && displayedDonor?.fileUrls && renderFiles()}
      {(sortedActiveCallForTenders.length !== 0 ||
        missingCallForTenders.length !== 0) && (
        <>
          <Text className={classes.subtitle}>Programmes de soutien actifs</Text>
        </>
      )}
      {sortedActiveCallForTenders.filter((c) => c?.published).length !== 0 && (
        <CallForTenderCards
          donorId={donorId}
          callForTenders={sortedActiveCallForTenders.filter(
            (c) => c?.published,
          )}
          isActive={true}
        />
      )}
      {missingCallForTenders.filter((c) => c?.published).length !== 0 && (
        <CallForTenderCards
          donorId={donorId}
          callForTenders={missingCallForTenders.filter((c) => c?.published)}
          isActive={true}
        />
      )}
      {/*{(user?.role === 'admin' || user?.donorId === donorId) &&*/}
      {/*  callForTenders.length !== 0 && (*/}
      {/*    <CallForTenderCards*/}
      {/*      donorId={donorId}*/}
      {/*      callForTenders={callForTenders}*/}
      {/*    />*/}
      {/*  )}*/}
      {sortedInactiveCallForTenders.length !== 0 && (
        <>
          <Text className={classes.subtitle}>
            Programmes de soutien inactifs
          </Text>
        </>
      )}
      {sortedInactiveCallForTenders.filter((c) => c.published).length !== 0 && (
        <CallForTenderCards
          donorId={donorId}
          callForTenders={sortedInactiveCallForTenders.filter(
            (c) => c.published,
          )}
          isActive={false}
        />
      )}
      {sortedFutureCallForTenders.length !== 0 && (
        <>
          <Text className={classes.subtitle}>
            Programmes de soutien à venir
          </Text>
        </>
      )}
      {sortedFutureCallForTenders.filter((c) => c.published).length !== 0 && (
        <CallForTenderCards
          donorId={donorId}
          callForTenders={sortedFutureCallForTenders.filter((c) => c.published)}
          isActive={false}
        />
      )}
    </div>
  );
};

export default DonorPageLeftColumn;
